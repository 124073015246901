@import '../styles/theme.css';

.game-play-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.player-stats {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.player-stat-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 10px 15px;
  margin: 5px;
  min-width: 150px;
  box-shadow: 0 2px 4px var(--shadow-color);
  transition: all 0.2s ease;
}

.player-stat-card.current-player {
  border: 2px solid var(--primary-color);
  background-color: var(--bg-tertiary);
}

.player-name {
  font-weight: bold;
  margin-bottom: 5px;
  color: var(--text-primary);
}

.player-lives {
  color: #e55;
  margin-bottom: 8px;
}

.category-badges {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.category-badge {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.category-badge.completed {
  transform: scale(1.1);
}

.found-groups {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.found-group {
  padding: 12px;
  border-radius: 8px;
  color: black;
  text-align: center;
}

.group-category {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 5px;
  color: black;
}

.group-words {
  font-size: 16px;
  color: black;
}

.word-grid-container {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}

.word-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
  width: 100%;
}

.word-card {
  background-color: var(--bg-secondary);
  border: 2px solid var(--border-color);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  color: var(--text-primary);
}

.word-card:hover {
  background-color: var(--bg-tertiary);
  transform: translateY(-2px);
}

.word-card.selected {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-hover);
  transform: translateY(-3px);
  box-shadow: 0 4px 8px var(--shadow-color);
}

.word-card.found {
  opacity: 0.5;
  cursor: not-allowed;
}

.game-instructions {
  margin: 20px 0;
  text-align: center;
  font-size: 18px;
  color: var(--text-secondary);
}

.game-controls {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.shuffle-button, .submit-button {
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.shuffle-button {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  color: var(--text-primary);
}

.shuffle-button:hover {
  background-color: var(--bg-tertiary);
}

.submit-button {
  background-color: var(--success-color);
  border: none;
  color: white;
}

.submit-button:hover {
  background-color: var(--success-hover);
}

.submit-button.disabled {
  background-color: var(--text-tertiary);
  cursor: not-allowed;
}

.submit-button.active {
  background-color: var(--success-color);
  cursor: pointer;
}

.selected-words {
  margin-top: 20px;
  text-align: center;
  width: 100%;
  max-width: 600px;
}

.selected-words-list {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 15px 0;
}

.selected-word {
  background-color: var(--primary-color);
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  font-weight: bold;
}

.submit-guess-button {
  background-color: var(--success-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.submit-guess-button:hover {
  background-color: var(--success-hover);
}

.loading-puzzle {
  text-align: center;
  padding: 40px;
  font-size: 18px;
  color: var(--text-secondary);
}

.countdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.countdown-number {
  font-size: 15rem;
  color: white;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.no-lives-message {
  margin-top: 20px;
  padding: 10px 15px;
  background-color: var(--danger-color);
  color: var(--danger-text);
  border-radius: 4px;
  text-align: center;
  font-weight: bold;
}

/* Add shake animation for incorrect guesses */
@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
}

.word-grid.shake {
  animation: shake 0.5s;
}

/* Make sure selected words stay visible during shake */
.word-card.selected {
  background-color: var(--primary-color);
  color: white;
  border-color: var(--primary-hover);
  transform: scale(1.05);
  transition: all 0.2s ease;
}

.feedback-message {
  margin: 15px 0;
  padding: 12px 20px;
  border-radius: 8px;
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.one-away-overlay {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  background-color: var(--warning-color);
  color: black;
  padding: 8px 15px;
  text-align: center;
  font-weight: bold;
  border-radius: 8px;
  z-index: 10;
  pointer-events: none;
  animation: fadeIn 0.3s ease-out;
  box-shadow: 0 2px 4px var(--shadow-color);
  width: fit-content;
  margin: 0 auto;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.back-to-lobby-button {
  display: block;
  margin: 30px auto 20px;
  padding: 12px 25px;
  font-size: 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  background-color: var(--text-tertiary);
  color: white;
  border: none;
  transition: background-color 0.2s;
}

.back-to-lobby-button:hover {
  background-color: var(--text-secondary);
}

/* Add styling for the deselect button */
.deselect-button {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s;
}

.deselect-button:hover {
  background-color: var(--bg-tertiary);
}

.deselect-button.active {
  background-color: var(--bg-tertiary);
  color: var(--text-primary);
}

.deselect-button.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.unfound-categories {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.unfound-categories h3 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: var(--text-primary);
}

/* Remove these since we're using the gap property instead */
.unfound-categories .found-group {
  margin-bottom: 0;
}

/* Make the last group not have bottom margin */
.unfound-categories .found-group:last-child {
  margin-bottom: 0;
} 