/* Add these styles to the top of your App.css file */
:root {
  /* Light mode variables */
  --bg-primary: #ffffff;
  --bg-secondary: #f8f9fa;
  --bg-tertiary: #e9ecef;
  --text-primary: #212529;
  --text-secondary: #495057;
  --text-tertiary: #6c757d;
  --border-color: #dee2e6;
  --shadow-color: rgba(0, 0, 0, 0.1);
  
  /* Ensure smooth transitions between modes */
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Dark mode variables */
.dark-mode {
  --bg-primary: #121212;
  --bg-secondary: #1e1e1e;
  --bg-tertiary: #2d2d2d;
  --text-primary: #e0e0e0;
  --text-secondary: #b0b0b0;
  --text-tertiary: #808080;
  --border-color: #333333;
  --shadow-color: rgba(0, 0, 0, 0.3);
}

/* Apply background color to html and body */
html, body {
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add these styles for solved categories */
.submission-item.correct {
  color: var(--success-color) !important;
  font-weight: bold;
}

/* Make the entire row green for correct submissions */
.submission-item.correct .submission-time,
.submission-item.correct .submission-words,
.submission-item.correct .submission-result {
  color: var(--success-color) !important;
}

/* Style for the checkmarks */
.submission-result ✓ {
  color: var(--success-color);
}

/* Ensure the SOLVED text is properly colored */
.game-result.solved-result {
  color: var(--success-color);
}
