@import '../styles/theme.css';

.game-room {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--bg-primary);
    color: var(--text-primary);
  }
  
  .game-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .game-status {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 16px;
    background-color: var(--bg-secondary);
    border-radius: 4px;
    color: var(--text-primary);
  }
  
  .waiting-room {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .player-list {
    background-color: var(--bg-primary);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px var(--shadow-color);
  }
  
  .player-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .player-item:last-child {
    border-bottom: none;
  }
  
  .player-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: var(--primary-color);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 15px;
  }
  
  .player-name {
    flex: 1;
    font-weight: bold;
    color: var(--text-primary);
  }
  
  .player-status {
    font-size: 0.9rem;
    padding: 3px 8px;
    border-radius: 12px;
    display: inline-block;
  }
  
  .player-status.ready {
    background-color: #2ecc71;
    color: white;
  }
  
  .player-status.not-ready {
    background-color: #e74c3c;
    color: white;
  }
  
  .room-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .room-actions button {
    padding: 10px 20px;
    font-size: 16px;
  }
  
  .room-info {
    background-color: #f8f9fa;
    padding: 15px;
    border-radius: 8px;
    margin-top: 20px;
    text-align: center;
  }
  
  .room-info strong {
    font-size: 24px;
    color: #2c3e50;
    padding: 5px 10px;
    background-color: #e9ecef;
    border-radius: 4px;
  }
  
  .game-content {
    display: grid;
    grid-template-columns: 300px 1fr;
    gap: 2rem;
  }
  
  .countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    font-size: 2rem;
    font-weight: bold;
  }
  
  .game-board {
    background-color: var(--bg-primary);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px var(--shadow-color);
    min-height: 400px;
  }
  
  @media (max-width: 768px) {
    .game-content {
      grid-template-columns: 1fr;
    }
  }
  
  .game-room-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .game-room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  
  .game-room-header h1 {
    margin: 0;
    font-size: 2rem;
    color: #2c3e50;
  }
  
  .game-status {
    display: flex;
    align-items: center;
  }
  
  .status-badge {
    background-color: var(--bg-secondary);
    padding: 8px 16px;
    border-radius: 20px;
    font-weight: 500;
    color: var(--text-primary);
  }
  
  .countdown {
    font-size: 3rem;
    font-weight: bold;
    color: var(--danger-color);
    background-color: var(--bg-secondary);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .game-room-content {
    display: flex;
    gap: 30px;
  }
  
  .players-section {
    flex: 1;
    background-color: var(--card-bg);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px var(--shadow-color);
    border: 1px solid var(--border-color);
  }
  
  .players-section h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--text-primary);
    font-size: 1.5rem;
  }
  
  .players-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .player-card {
    display: flex;
    align-items: center;
    padding: 15px;
    background-color: var(--bg-secondary);
    border-radius: 8px;
    transition: transform 0.2s;
    margin-bottom: 10px;
  }
  
  .player-card:hover {
    transform: translateY(-2px);
  }
  
  .player-avatar {
    font-size: 1.8rem;
    margin-right: 15px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: white;
    border-radius: 50%;
  }
  
  .player-info {
    flex: 1;
  }
  
  .player-name {
    font-weight: bold;
    font-size: 1.1rem;
    color: var(--text-primary);
    margin-bottom: 5px;
  }
  
  .player-rating {
    font-size: 0.9rem;
    color: var(--text-tertiary);
    margin-bottom: 5px;
  }
  
  .room-info-section {
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .room-code-container {
    background-color: var(--bg-primary);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px var(--shadow-color);
    text-align: center;
  }
  
  .room-code-container h3 {
    margin-top: 0;
    margin-bottom: 15px;
    color: var(--text-primary);
    font-size: 1.2rem;
  }
  
  .room-code {
    font-size: 2.5rem;
    font-weight: bold;
    color: var(--primary-color);
    margin: 15px 0;
    letter-spacing: 2px;
  }
  
  .copy-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    margin: 0 auto;
  }
  
  .copy-button:hover {
    background-color: var(--primary-hover);
  }
  
  .copy-button.copied {
    background-color: var(--success-color);
  }
  
  .copy-icon {
    font-size: 1rem;
  }
  
  .copy-icon.check {
    animation: checkmark-appear 0.3s ease-in-out;
  }
  
  @keyframes checkmark-appear {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .room-actions {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .game-room-footer {
    display: flex;
    gap: 15px;
    margin-top: 30px;
    justify-content: center;
  }
  
  .ready-button, .leave-button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s;
    min-width: 120px;
  }
  
  .ready-button {
    background-color: var(--bg-secondary);
    color: var(--text-primary);
    border: 2px solid var(--primary-color);
  }
  
  .ready-button:hover {
    background-color: var(--bg-tertiary);
  }
  
  .ready-button.ready-active {
    background-color: var(--success-color);
    color: white;
    border-color: var(--success-hover);
  }
  
  .leave-button {
    background-color: #e74c3c;
    color: white;
  }
  
  .leave-button:hover {
    background-color: #c0392b;
  }
  
  @media (max-width: 768px) {
    .game-room-content {
      flex-direction: column;
    }
    
    .room-info-section {
      width: 100%;
    }
    
    .game-room-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 15px;
    }
    
    .countdown {
      width: 60px;
      height: 60px;
      font-size: 2rem;
    }
  }
  
  .game-options-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .game-option-card {
    flex: 1;
    background-color: var(--card-bg);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px var(--shadow-color);
    max-width: calc(50% - 10px);
    border: 1px solid var(--border-color);
  }
  
  .room-code-input {
    width: 100%;
    max-width: 250px;
    padding: 12px;
    border: 1px solid var(--input-border);
    border-radius: 4px;
    margin-bottom: 20px;
    font-size: 16px;
    margin: 0 auto 20px;
    display: block;
    background-color: var(--input-bg);
    color: var(--input-text);
  }
  
  @media (max-width: 768px) {
    .game-options-container {
      flex-direction: column;
    }
    
    .game-option-card {
      max-width: 100%;
    }
  }
  
  .countdown-display {
    font-size: 2rem;
    font-weight: bold;
    color: var(--primary-color);
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .countdown-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .countdown-number {
    font-size: 15rem;
    color: white;
    animation: pulse 1s infinite;
  }
  
  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  /* Game room loading and leaving screens */
  .game-room-loading, .leaving-game-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
    max-width: 600px;
    margin: 2rem auto;
    text-align: center;
    padding: 2rem;
    background-color: var(--card-bg);
    border-radius: 12px;
    box-shadow: 0 4px 20px var(--shadow-color);
    border: 1px solid var(--border-color);
  }
  
  .game-room-loading h2, .leaving-game-container h2 {
    color: var(--primary-color);
    font-size: 2.2rem;
    margin-bottom: 1.5rem;
  }
  
  .game-room-loading p, .leaving-game-container p {
    color: var(--text-secondary);
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  
  /* Loading spinner */
  .loading-spinner {
    width: 60px;
    height: 60px;
    border: 5px solid var(--bg-tertiary);
    border-radius: 50%;
    border-top-color: var(--primary-color);
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 2rem;
  }
  
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  /* Animated dots for "Leaving Game..." */
  .dot-animation::after {
    content: '';
    animation: dots 1.5s infinite;
    color: var(--text-secondary);
  }
  
  @keyframes dots {
    0%, 20% { content: '.'; }
    40% { content: '..'; }
    60%, 100% { content: '...'; }
  }

  /* Fix submission history text colors */
  .submission-time {
    color: #e74c3c;
    font-family: monospace;
  }

  .submission-words {
    color: #e74c3c;
  }

  .submission-result {
    color: #e74c3c;
  }

  /* Fix game history failed status */
  .failed-result {
    color: #e74c3c !important;
    font-weight: bold;
  }

  /* Fix game history submission items */
  .submission-item .submission-time {
    color: #e74c3c;
    font-family: monospace;
  }

  .submission-item.incorrect {
    color: #e74c3c;
  }

  /* Fix game status indicators */
  .status-failed, .status-failed span {
    color: #e74c3c !important;
  }

  /* Make sure the FAILED text in game history is visible */
  .game-history-item .failed {
    color: #e74c3c !important;
  }

  /* Ensure border color has enough contrast in dark mode */
  body.dark-mode .players-section {
    border: 1px solid rgba(255, 255, 255, 0.2);
    background-color: #1e1e1e; /* Slightly lighter than black background */
  }

  /* Fix player card background in dark mode */
  body.dark-mode .player-card {
    background-color: #2a2a2a; /* Even lighter than the container */
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  /* Fix individual player items border in dark mode */
  body.dark-mode .player-item {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }