/* Add these dark mode variables at the top of your file */
:root {
  /* Light mode (default) variables */
  --bg-primary: #ffffff;
  --bg-secondary: #f8f9fa;
  --bg-tertiary: #e9ecef;
  --text-primary: #2c3e50;
  --text-secondary: #555;
  --text-tertiary: #7f8c8d;
  --border-color: #ddd;
  --shadow-color: rgba(0, 0, 0, 0.1);
  --primary-color: #3498db;
  --primary-hover: #2980b9;
  --secondary-color: #f8f9fa;
  --secondary-hover: #e9ecef;
  --success-color: #4caf50;
  --success-hover: #3d8b40;
  --danger-color: #f8d7da;
  --danger-text: #721c24;
  --info-color: #d1ecf1;
  --info-text: #0c5460;
}

/* Dark mode variables */
.dark-mode {
  --bg-primary: #121212;
  --bg-secondary: #1e1e1e;
  --bg-tertiary: #2d2d2d;
  --text-primary: #e0e0e0;
  --text-secondary: #b0b0b0;
  --text-tertiary: #909090;
  --border-color: #444;
  --shadow-color: rgba(0, 0, 0, 0.3);
  --primary-color: #2980b9;
  --primary-hover: #3498db;
  --secondary-color: #2d2d2d;
  --secondary-hover: #3d3d3d;
  --success-color: #3d8b40;
  --success-hover: #4caf50;
  --danger-color: #5c2329;
  --danger-text: #f8d7da;
  --info-color: #0c3c44;
  --info-text: #d1ecf1;
}

.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.home-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  background-color: var(--bg-primary);
}

.home-header {
  margin-bottom: 40px;
}

.home-header h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--text-primary);
}

.home-header h2 {
  font-size: 1.2rem;
  color: var(--text-secondary);
  margin-bottom: 20px;
}

.game-options {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 40px;
}

.option-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 2px 5px var(--shadow-color);
  margin-bottom: 20px;
}

.option-card h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--text-primary);
  font-size: 1.5rem;
}

/* Improved checkbox styling */
.form-check {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  cursor: pointer;
  accent-color: #3498db;
}

.form-check-label {
  font-size: 1rem;
  color: var(--text-primary);
  cursor: pointer;
}

/* Custom checkbox container */
.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-grow: 1;
}

.checkbox-container label {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.2s;
  width: 100%;
}

.checkbox-container label:hover {
  background-color: var(--bg-tertiary);
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  accent-color: #3498db;
}

.room-code-input {
  width: 100%;
  max-width: 250px;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  margin: 0 auto 20px;
  font-size: 16px;
  display: block;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.primary-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  margin-top: auto;
}

.primary-button:hover {
  background-color: var(--primary-hover);
}

/* How to Play section */
.how-to-play {
  margin-top: 20px;
}

.how-to-play h2 {
  margin-bottom: 25px;
  color: var(--text-primary);
  text-align: center;
  font-size: 1.8rem;
}

.steps-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.step-card {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 20px;
  flex: 1;
  box-shadow: 0 2px 5px var(--shadow-color);
  text-align: center;
}

.step-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #3498db;
  color: white;
  border-radius: 50%;
  margin: 0 auto 15px;
  font-weight: bold;
  font-size: 20px;
}

.step-card h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: var(--text-primary);
}

.step-card p {
  color: var(--text-secondary);
  font-size: 14px;
}

/* Auth prompt for non-logged in users */
.auth-prompt {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  padding: 30px;
  margin: 0 auto 40px;
  max-width: 500px;
  box-shadow: 0 2px 5px var(--shadow-color);
  text-align: center;
}

.auth-prompt h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--text-primary);
  font-size: 1.2rem;
}

.auth-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.auth-buttons button {
  padding: 10px 20px;
  min-width: 120px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #3498db;
  color: white;
  border-color: #3498db;
}

.btn-primary:hover {
  background-color: #2980b9;
  border-color: #2980b9;
}

.btn-secondary {
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border-color: var(--border-color);
}

.btn-secondary:hover {
  background-color: var(--bg-tertiary);
  border-color: var(--border-color);
}

/* Responsive styles */
@media (max-width: 768px) {
  .steps-container {
    flex-direction: column;
  }
  
  .step-card {
    margin-bottom: 20px;
  }
  
  .auth-buttons {
    flex-direction: column;
  }
  
  .auth-buttons .btn {
    width: 100%;
  }
}

/* Public games list */
.public-games {
  margin-top: 40px;
  width: 100%;
}

.public-games h3 {
  margin-bottom: 15px;
  color: #2c3e50;
  text-align: left;
}

.games-list {
  width: 100%;
  border-collapse: collapse;
}

.games-list th {
  background-color: #f2f2f2;
  padding: 12px;
  text-align: left;
  font-weight: 600;
}

.games-list td {
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.games-list tr:hover {
  background-color: #f8f9fa;
}

.join-btn {
  padding: 6px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.join-btn:hover {
  background-color: #2980b9;
}

.no-games {
  text-align: center;
  color: #7f8c8d;
  padding: 20px;
  font-style: italic;
}

/* Guest notification banner */
.guest-banner {
  background-color: #d1ecf1;
  color: #0c5460;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
  text-align: center;
  border: 1px solid #bee5eb;
}

/* Game options layout */
.game-options-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 40px;
}

.game-option-card {
  flex: 1;
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 25px;
  box-shadow: 0 2px 4px var(--shadow-color);
  display: flex;
  flex-direction: column;
  height: 200px;
}

.game-option-card h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: var(--text-primary);
  font-size: 1.5rem;
}

/* Create a horizontal layout for checkboxes */
.options-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-check {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background-color: var(--bg-secondary);
  border-radius: 4px;
  transition: background-color 0.2s;
}

.form-check:hover {
  background-color: var(--bg-tertiary);
}

.form-check-input {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  accent-color: #3498db;
}

/* Guest note styling */
.guest-note {
  background-color: var(--bg-secondary);
  padding: 15px;
  border-radius: 6px;
  margin: 15px 0;
  font-size: 0.95rem;
  color: var(--text-secondary);
  border-left: 4px solid #f39c12;
  text-align: center;
}

/* Alert styling */
.alert {
  padding: 15px;
  margin: 20px 0;
  border-radius: 4px;
  text-align: center;
}

.alert-danger {
  background-color: var(--danger-color);
  color: var(--danger-text);
  border: 1px solid var(--danger-color);
}

.alert-info {
  background-color: var(--info-color);
  color: var(--info-text);
  border: 1px solid var(--info-color);
}

/* Add this to the HomePage.css file */
.auth-container {
  background-color: var(--bg-primary);
  border-radius: 8px;
  box-shadow: 0 2px 10px var(--shadow-color);
  padding: 30px;
  margin: 30px auto;
  max-width: 500px;
  text-align: center;
}

.auth-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
}

.auth-buttons .btn {
  min-width: 120px;
}

.alert {
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  text-align: center;
}

.alert-danger {
  background-color: var(--danger-color);
  color: var(--danger-text);
  border: 1px solid var(--danger-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .game-options-container {
    flex-direction: column;
  }
  
  .game-option-card {
    height: auto;
    min-height: 180px;
  }
}

/* Public Games List */
.public-games-section {
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
}

.public-games-section h2 {
  margin-bottom: 20px;
  color: var(--text-primary);
  text-align: center;
  font-size: 1.8rem;
}

.public-games-list {
  background-color: var(--bg-primary);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 5px var(--shadow-color);
}

.public-games-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.public-games-table th, 
.public-games-table td {
  text-align: left;
  padding: 12px 15px;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.public-games-table th:nth-child(1),
.public-games-table td:nth-child(1) {
  width: 25%;
}

.public-games-table th:nth-child(2),
.public-games-table td:nth-child(2) {
  width: 15%;
}

.public-games-table th:nth-child(3),
.public-games-table td:nth-child(3) {
  width: 20%;
}

.public-games-table th:nth-child(4),
.public-games-table td:nth-child(4) {
  width: 20%;
}

.public-games-table th:nth-child(5),
.public-games-table td:nth-child(5) {
  width: 20%;
  text-align: center;
}

.join-button {
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s;
  display: inline-block;
}

.join-button:hover {
  background-color: #2980b9;
}

.no-games-message {
  text-align: center;
  padding: 20px;
  color: var(--text-tertiary);
  font-style: italic;
}

.refresh-button {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.refresh-button:hover {
  background-color: var(--bg-tertiary);
}

/* Active Game Banner */
.active-game-banner {
  background-color: var(--bg-secondary);
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  padding: 15px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary);
}

.return-game-button {
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.return-game-button:hover {
  background-color: #3a7bc8;
}

.navbar-button.active-game-button {
  background-color: #4caf50;
}

.navbar-button.active-game-button:hover {
  background-color: #3d8b40;
}

/* Add styling for game status text */
.game-status-text {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  color: var(--text-secondary);
}

/* Maintenance Banner */
.maintenance-banner {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  border-radius: 8px;
  padding: 15px 20px;
  margin: 20px auto;
  text-align: center;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 2px 5px var(--shadow-color);
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.maintenance-banner.info {
  background-color: var(--info-color);
  color: var(--info-text);
  border-color: var(--info-color);
}

.maintenance-banner.warning {
  background-color: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
}

.maintenance-banner-icon {
  font-size: 1.5rem;
}

/* Dark mode adjustments */
.dark-mode .maintenance-banner.warning {
  background-color: #3a3000;
  color: #ffe69c;
  border-color: #665800;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .maintenance-banner {
    padding: 12px 15px;
    font-size: 0.9rem;
  }
}
