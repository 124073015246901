.navbar {
  background-color: #2c3e50;
  color: white;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  height: 60px;
}

.navbar-brand {
  font-size: 1.5rem;
  font-weight: 700;
}

.navbar-brand a {
  color: white;
  text-decoration: none;
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-item {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.navbar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-button {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 20px;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.navbar-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-button {
  background-color: #e74c3c;
  color: white;
}

.logout-button:hover {
  background-color: #c0392b;
}

/* Dark mode toggle button */
.theme-toggle {
  background-color: transparent;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-right: 10px;
}

.theme-toggle:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    height: auto;
    padding: 15px 0;
  }
  
  .navbar-menu {
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .navbar-item, .navbar-button {
    margin: 5px;
  }
}

/* Add these styles for the fixed dark mode toggle button */
.theme-toggle-fixed {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  box-shadow: 0 2px 10px var(--shadow-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
}

.theme-toggle-fixed:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 15px var(--shadow-color);
}

/* You can remove or comment out the old theme-toggle styles if you want */ 