.auth-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  padding: 20px;
  background-color: var(--bg-primary);
}

.auth-container {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 10px var(--shadow-color);
  padding: 30px;
  width: 100%;
  max-width: 360px;
  text-align: center;
}

.auth-container h1 {
  margin-bottom: 24px;
  color: var(--text-primary);
  font-size: 28px;
}

.auth-error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.auth-form {
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: var(--text-primary);
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
  background-color: var(--input-bg);
  color: var(--input-text);
  max-width: 100%;
  box-sizing: border-box;
}

.form-group input:focus {
  border-color: var(--primary-color);
  outline: none;
}

.auth-button {
  width: 100%;
  padding: 12px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auth-button:hover {
  background-color: var(--primary-hover);
}

.auth-button:disabled {
  background-color: var(--primary-color-light, #a0c4f0);
  opacity: 0.8;
  cursor: not-allowed;
  color: white !important;
}

.auth-divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.auth-divider::before,
.auth-divider::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid var(--border-color);
}

.auth-divider span {
  padding: 0 10px;
  color: var(--text-tertiary);
  font-size: 14px;
}

.guest-button {
  width: 100%;
  padding: 12px;
  background-color: var(--bg-secondary);
  color: var(--text-primary);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 20px;
}

.guest-button:hover {
  background-color: var(--bg-tertiary);
  border-color: var(--border-color);
}

.auth-links {
  margin-top: 20px;
  margin-bottom: 15px;
}

.auth-links p {
  margin: 8px 0;
  color: var(--text-secondary);
}

.auth-links a {
  color: var(--primary-color);
  text-decoration: none;
}

.auth-links a:hover {
  text-decoration: underline;
}

.auth-footer {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid var(--border-color);
}

.back-link, .register-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 14px;
  transition: color 0.3s;
}

.back-link:hover, .register-link:hover {
  color: var(--text-tertiary);
  text-decoration: underline;
}

.auth-options {
  margin-top: 20px;
  text-align: center;
  padding: 20px;
  background-color: var(--bg-tertiary);
  border-radius: 8px;
}

.auth-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.auth-buttons .btn {
  padding: 10px 20px;
}

.google-button {
  width: 100%;
  padding: 12px;
  background-color: white;
  color: #444;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.google-button:hover {
  background-color: #f1f1f1;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.google-button img {
  width: 18px;
  height: 18px;
}

.google-login-container {
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
}

.auth-footer {
  margin-top: 1.5rem;
  text-align: center;
}

.auth-footer p {
  margin: 0.5rem 0;
  color: var(--text-secondary);
}

.auth-footer a {
  color: var(--primary-color);
  text-decoration: none;
}

.auth-footer a:hover {
  text-decoration: underline;
}

.register-prompt {
  margin: 20px 0;
  text-align: center;
  padding: 15px;
  background-color: var(--bg-tertiary);
  border-radius: 8px;
}

.register-prompt p {
  margin-bottom: 10px;
  font-size: 16px;
  color: var(--text-primary);
}

.register-link {
  display: inline-block;
  padding: 8px 20px;
  background-color: var(--success-color);
  color: white;
  text-decoration: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.register-link:hover {
  background-color: var(--success-hover);
  text-decoration: none;
}

.auth-success {
  background-color: #d4edda;
  color: #155724;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-bottom: 20px;
}

.forgot-password-link {
  text-align: right;
  margin-bottom: 15px;
  font-size: 0.9rem;
}

.forgot-password-link a {
  color: #6c757d;
  text-decoration: none;
}

.forgot-password-link a:hover {
  text-decoration: underline;
  color: #007bff;
}

/* Specific styling for the verification page buttons */
.auth-success .auth-button,
.auth-error .auth-button {
  display: inline-block;
  background-color: #4285f4;
  color: white;
  padding: 12px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 15px;
  transition: background-color 0.3s;
  border: none;
  cursor: pointer;
}

.auth-success .auth-button:hover,
.auth-error .auth-button:hover {
  background-color: #3367d6;
  text-decoration: none;
}

/* This ensures the login button in the verification success message is properly styled */
.auth-success .login-button {
  margin: 15px auto 5px;
  display: block;
  max-width: 200px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.resend-verification {
  margin: 20px 0;
  text-align: center;
}

.resend-verification p {
  margin-bottom: 10px;
  color: var(--text-secondary);
}

.resend-button {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

/* Style for the "Email Sent!" text */
.email-sent-message {
  display: inline-block;
  padding: 10px 20px;
  background-color: var(--success-bg, #d4edda);
  color: var(--success-text, #155724);
  border-radius: 4px;
  font-weight: 500;
  margin-top: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Make sure we have these variables in theme.css */
:root {
  /* ... existing variables ... */
  --success-bg: #d4edda;
  --success-text: #155724;
}

[data-theme='dark'] {
  /* ... existing dark theme variables ... */
  --success-bg: #1e4620;
  --success-text: #8fd19e;
} 