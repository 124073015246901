@import '../styles/theme.css';

.leaderboard-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--bg-primary);
}

.leaderboard-container {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--shadow-color);
  overflow: hidden;
}

.leaderboard-container h1 {
  text-align: center;
  padding: 1.5rem;
  margin: 0;
  background-color: var(--bg-tertiary);
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.loading, .error-message {
  text-align: center;
  padding: 2rem;
  color: var(--text-primary);
}

.error-message {
  color: var(--danger-text);
}

.leaderboard-table {
  width: 100%;
}

.leaderboard-header {
  display: flex;
  background-color: var(--bg-tertiary);
  font-weight: bold;
  border-bottom: 2px solid var(--border-color);
  color: var(--text-primary);
}

.leaderboard-row {
  display: flex;
  border-bottom: 1px solid var(--border-color);
  color: var(--text-primary);
}

.leaderboard-row:nth-child(even) {
  background-color: var(--bg-secondary);
}

.leaderboard-row:hover {
  background-color: var(--bg-tertiary);
}

.rank-column,
.player-column,
.rating-column,
.games-column,
.winrate-column {
  padding: 1rem;
}

.rank-column {
  width: 10%;
  text-align: center;
}

.player-column {
  width: 40%;
}

.rating-column {
  width: 15%;
  text-align: center;
}

.games-column {
  width: 15%;
  text-align: center;
}

.winrate-column {
  width: 20%;
  text-align: center;
}

.no-data-message {
  text-align: center;
  padding: 2rem;
  color: var(--text-tertiary);
  font-style: italic;
} 