@import '../styles/theme.css';

.profile-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--bg-primary);
}

.profile-container {
  background-color: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 4px 6px var(--shadow-color);
  overflow: hidden;
}

.profile-header {
  display: flex;
  align-items: center;
  padding: 2rem;
  background-color: var(--bg-tertiary);
  border-bottom: 1px solid var(--border-color);
}

.profile-avatar {
  margin-right: 2rem;
}

.profile-avatar img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.avatar-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: bold;
}

.profile-info h1 {
  margin: 0 0 0.5rem 0;
  color: var(--text-primary);
}

.profile-email {
  color: var(--text-tertiary);
  margin: 0 0 0.5rem 0;
}

.profile-rating {
  font-weight: bold;
  margin: 0;
}

.profile-stats {
  display: flex;
  justify-content: space-around;
  padding: 1.5rem;
  border-bottom: 1px solid var(--border-color);
}

.stat-card {
  text-align: center;
  color: var(--text-primary);
}

.stat-value {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.stat-label {
  color: var(--text-secondary);
}

.profile-section {
  padding: 1.5rem;
}

.profile-section h2 {
  margin-top: 0;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.5rem;
}

.game-history {
  min-height: 200px;
}

.empty-state {
  text-align: center;
  color: var(--text-secondary);
  padding: 2rem;
}

.profile-actions {
  padding: 2rem;
}

.profile-actions button {
  margin-right: 1rem;
}

/* Loading state - remove or modify this to remove the loading circle */
.loading-spinner {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
  /* Remove any animation or spinner styles here */
}

/* Error message */
.error-message {
  text-align: center;
  padding: 2rem;
  color: var(--danger-color);
}

.retry-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Game History Styles */
.game-history-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
}

.game-history-item {
  background-color: var(--bg-primary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.game-header {
  display: flex;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  background-color: var(--bg-primary);
  border-bottom: 1px solid var(--border-color);
}

.game-date {
  color: var(--text-secondary);
  font-size: 0.9rem;
  width: 30%;
}

.game-result {
  width: 15%;
  text-align: center;
}

.solved-result {
  color: var(--success-color);
  font-weight: bold;
}

.failed-result {
  color: var(--danger-color);
  font-weight: bold;
}

.game-stats {
  width: 45%;
  color: var(--text-secondary);
  font-size: 0.9rem;
  display: flex;
  gap: 8px;
}

.expand-icon {
  width: 10%;
  text-align: right;
  font-size: 16px;
  color: var(--text-secondary);
  margin-left: auto;
}

.game-details {
  background-color: var(--bg-tertiary);
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
}

.submission-container {
  background-color: var(--bg-secondary);
  padding: 16px;
  border-radius: 0 0 8px 8px;
}

.submission-container h4 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
  color: var(--text-primary);
}

.submission-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
}

.submission-item {
  display: flex;
  padding: 8px 5px;
  border-bottom: 1px solid var(--border-color);
  font-size: 0.9rem;
  color: var(--text-primary);
  align-items: center;
}

.submission-item:last-child {
  border-bottom: none;
}

.submission-time {
  width: 80px;
  font-family: monospace;
}

.submission-words {
  flex: 1;
  margin: 0 10px;
}

.submission-result {
  width: 120px;
  text-align: right;
}

.submission-item.correct {
  color: var(--success-color);
}

.submission-item.incorrect {
  color: var(--danger-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .game-header {
    flex-wrap: wrap;
  }
  
  .game-date {
    width: 50%;
  }
  
  .game-result {
    width: 40%;
  }
  
  .expand-icon {
    width: 10%;
  }
  
  .game-stats {
    width: 100%;
    margin-top: 8px;
  }
}

.username-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 0.8rem;
}

.username-edit-form {
  margin-bottom: 10px;
}

.username-input {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 8px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.edit-buttons {
  display: flex;
  gap: 8px;
}

.save-button, .cancel-button {
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
}

.save-button {
  background-color: #4a90e2;
  color: white;
  border: none;
}

.cancel-button {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
}

.error-message {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 5px;
}

.success-message {
  color: #2ecc71;
  font-size: 0.9rem;
  margin-top: 5px;
}

.save-button.loading {
  background-color: var(--primary-color-light);
  cursor: not-allowed;
}

.error-message {
  color: #e74c3c;
  font-size: 0.9rem;
  margin-top: 5px;
  padding: 5px;
  background-color: rgba(231, 76, 60, 0.1);
  border-radius: 4px;
}

/* Add these styles to ProfilePage.css */

.loading-container {
  text-align: center;
  padding: 2rem;
  color: var(--text-secondary);
}

.error-container {
  text-align: center;
  padding: 2rem;
  color: var(--danger-color);
}

.username-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.edit-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 0.8rem;
}

.username-edit-form {
  margin-bottom: 10px;
}

.username-input {
  padding: 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  margin-bottom: 8px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
}

.save-button.loading {
  background-color: var(--primary-color-light);
  cursor: not-allowed;
}

/* Add to AuthPages.css */
.resend-verification {
  margin: 20px 0;
  text-align: center;
}

.resend-verification p {
  margin-bottom: 10px;
  color: var(--text-secondary);
}

.resend-button {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.resend-success {
  padding: 10px;
  background-color: var(--success-bg, #d4edda);
  color: var(--success-text, #155724);
  border-radius: 4px;
  margin-top: 10px;
  font-weight: 500;
}

/* Make sure we have these variables in theme.css */
:root {
  /* ... existing variables ... */
  --success-bg: #d4edda;
  --success-text: #155724;
}

[data-theme='dark'] {
  /* ... existing dark theme variables ... */
  --success-bg: #1e4620;
  --success-text: #8fd19e;
} 