:root {
  /* Light mode (default) */
  --bg-primary: #ffffff;
  --bg-secondary: #f0f0f0;
  --bg-tertiary: #e0e0e0;
  --text-primary: #2c3e50;
  --text-secondary: #555555;
  --text-tertiary: #6c757d;
  --border-color: #dddddd;
  --shadow-color: rgba(0, 0, 0, 0.1);
  
  /* Game colors */
  --primary-color: #3498db;
  --primary-hover: #2980b9;
  --secondary-color: #f39c12;
  --secondary-hover: #e67e22;
  --success-color: #2ecc71;
  --success-hover: #27ae60;
  --danger-color: #e74c3c;
  --danger-hover: #c0392b;
  --warning-color: #f1c40f;
  
  /* Additional colors */
  --info-color: #d1ecf1;
  --info-text: #0c5460;
  --danger-bg: #f8d7da;
  --danger-text: #721c24;
  --white: #ffffff;
  --black: #000000;
  
  /* Card and form colors */
  --card-bg: #ffffff;
  --input-bg: #ffffff;
  --input-text: #2c3e50;
  --input-border: #dddddd;
}

/* Dark mode */
.dark-mode {
  --bg-primary: #121212;
  --bg-secondary: #1e1e1e;
  --bg-tertiary: #2d2d2d;
  --text-primary: #f0f0f0;
  --text-secondary: #cccccc;
  --text-tertiary: #aaaaaa;
  --border-color: #444444;
  --shadow-color: rgba(0, 0, 0, 0.3);
  
  /* Game colors - slightly adjusted for dark mode */
  --primary-color: #2196f3;
  --primary-hover: #1976d2;
  --secondary-color: #ff9800;
  --secondary-hover: #f57c00;
  --success-color: #4caf50;
  --success-hover: #388e3c;
  --danger-color: #f44336;
  --danger-hover: #d32f2f;
  --warning-color: #ffeb3b;
  
  /* Additional colors for dark mode */
  --info-color: #0c3c44;
  --info-text: #d1ecf1;
  --danger-bg: #5c2329;
  --danger-text: #f8d7da;
  
  /* Card and form colors for dark mode */
  --card-bg: #1e1e1e;
  --input-bg: #2d2d2d;
  --input-text: #f0f0f0;
  --input-border: #444444;
}

/* Smooth transitions when switching themes */
body {
  transition: background-color 0.3s ease, color 0.3s ease;
  background-color: var(--bg-primary);
  color: var(--text-primary);
} 