@import '../styles/theme.css';

.game-timer {
  display: inline-flex;
  align-items: center;
  background-color: var(--bg-secondary);
  padding: 5px 10px;
  border-radius: 4px;
  font-family: monospace;
  font-size: 1.2rem;
  margin: 10px 0;
  color: var(--text-primary);
}

.timer-label {
  margin-right: 8px;
  font-weight: bold;
}

.timer-value {
  min-width: 80px;
} 